@use 'common'

html,
body
	color: #000
	padding: 0
	margin: 0
	font-family: 'Source Sans Pro', sans-serif
	background-color: #fbfbfb

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

.minorcds-content
	max-width: 800px
	margin: 0 auto
	&.denied
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		height: 100vh
	@media(max-width: 900px)
		max-width: calc(100% - 40px)

.album
	padding: 50px 0
	margin: 0 auto
	&-title
		text-align: center
		margin: 0 auto
		font-size: 50px
		margin-bottom: 30px
	&-image
		max-width: 300px
		margin: 0 auto
	&-inner
		&-tracks
			list-style: none
			padding: 0
			li + li
				margin-top: 20px
			li
				background-color: #e74c3c
				color: #fff
				padding: 15px
				border-radius: 5px
				display: flex
				//flex-wrap: wrap
				align-items: center
				justify-content: space-between
				span
					font-size: 24px
					font-weight: bold
					margin-right: 15px
					flex-shrink: 0
				audio
					width: 60%
				@media(max-width: 900px)
					flex-wrap: wrap
					audio
						width: 100%
						margin-top: 10px
